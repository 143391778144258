import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchExamQuestions(ctx, { id }) {
      //console.log("survey questions Fetching");

      

      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/examquestions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatequestion(ctx, userData ) {
      

      

      return new Promise((resolve, reject) => {
        var id=userData.id;

        //console.log("updating+ "+ id);
        //console.log(userData);

        axios
          .put(`/lms/admin/course/examupdatequestion/${id}` , userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourse(ctx, { id }) {
      //console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`//lms/admin/course/examsurveydetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateCourseInfo(ctx, { id }) {
      //console.log("update course  is launching with id : "+id);
      return ;
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addExamQuestion(ctx, userData) {

      //console.log("course Create triggered");
      //console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/examcreatequestion', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    addSurvey(ctx, userData) {

      //console.log("course Create triggered");
      //console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/survey/admin/survey', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    draftCourse(ctx, userData) {

      //console.log("draftCourse Create triggered");
      //console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/draft', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    publishCourse(ctx, userData) {

      //console.log("publishCourse Create triggered");
      //console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/survey/admin/surveypublish', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
